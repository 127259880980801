
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {namespace} from 'vuex-class';

    import PlaceInput from '@/components/forms/PlaceInput.vue';
    import QuickSearchInput from '@/components/forms/QuickSearchInput.vue';
    import SpeciesInput from '@/components/forms/SpeciesInput.vue';
    

    import {
        IPlaceSuggestion,
        ISpecies,
        IUser,
    } from '@/types';
    import {SearchModule} from '@/store/modules/search.module';
    import {AnimalModule} from '@/store/modules';

    const accountNamespace = namespace('account');

    @Component({
        components: {
            PlaceInput,
            QuickSearchInput,
            SpeciesInput,
        },
    })
    export default class ViewHome extends Vue {
        public searchDrawerIsOpen: boolean = false;
        public location: IPlaceSuggestion|null = null;
        public species: ISpecies|null = null;
        public speciesByType: ISpecies[] = [];
        public activeSlide: number = 0;
        public openDialog: boolean = false;

        @accountNamespace.Getter('loggedUser')
        public loggedUser!: IUser;

        private sliderImages = [
            {
                src: require('@/assets/home/s1.jpg'),
                title: '<span class="slide-h1 font-weight-black"><span class="secondary--text">Trouvez</span> un vétérinaire !</span><br> <span class="slide-h2">Prenez <span class="secondary--text">RDV en ligne</span></span>',
                position: 'top',
            },
            {
                src: require('@/assets/home/s2.jpg'),
                title: '<span class="slide-h1 font-weight-black"><span class="secondary--text">Trouvez</span> un vétérinaire !</span><br> <span class="slide-h2">Prenez <span class="secondary--text">RDV en ligne</span></span>',
                position: 'top',
            },
            {
                src: require('@/assets/home/s3.jpg'),
                title: '<span class="slide-h1 font-weight-black"><span class="secondary--text">Trouvez</span> un vétérinaire !</span><br> <span class="slide-h2">Prenez <span class="secondary--text">RDV en ligne</span></span>',
                position: 'bottom',
            },

        ];

        private cities = [
            {
                title: 'Paris',
                url: '/recherche?latitude=48.85718&longitude=2.34141&range=any&sort=availability&page=1&per_page=10',
            },
            {
                title: 'Marseille',
                url: '/recherche?latitude=43.29338&longitude=5.37132&range=any&sort=availability&page=1&per_page=10',
            },
            {
                title: 'Lyon',
                url: '/recherche?latitude=45.75917&longitude=4.82966&range=any&sort=availability&page=1&per_page=10',
            },
            {
                title: 'Strasbourg',
                url: '/recherche?latitude=48.58504&longitude=7.73643&range=any&sort=availability&page=1&per_page=10',
            },
            {
                title: 'Amiens',
                url: '/recherche?latitude=49.89469&longitude=2.29327&range=any&sort=availability&page=1&per_page=10',
            },
            {
                title: 'Nantes',
                url: '/recherche?latitude=47.21812&longitude=-1.55306&range=any&sort=availability&page=1&per_page=10',
            },
            {
                title: 'Lille',
                url: '/recherche?latitude=50.62821&longitude=3.06881&range=any&sort=availability&page=1&per_page=10',
            },
        ];

        private specialties = [
            {
                title: 'Chien',
                url: '/recherche?latitude=48.85718&longitude=2.34141&species=chien&range=any&sort=availability&page=1&per_page=10',
            },
            {
                title: 'Chat',
                url: '/recherche?latitude=48.85718&longitude=2.34141&species=chat&range=any&sort=availability&page=1&per_page=10',
            },
            {
                title: 'NAC',
                url: '',
            },
            {
                title: 'Aviaire',
                url: '/recherche?latitude=48.85718&longitude=2.34141&species=oiseau&range=any&sort=availability&page=1&per_page=10',
            },
            {
                title: 'Reptile',
                url: '/recherche?latitude=48.85718&longitude=2.34141&species=reptile&range=any&sort=availability&page=1&per_page=10',
            },
            {
                title: 'Rongeur',
                url: '/recherche?latitude=48.85718&longitude=2.34141&species=rongeur&range=any&sort=availability&page=1&per_page=10',
            },
            {
                title: 'Poisson',
                url: '/recherche?latitude=48.85718&longitude=2.34141&species=poisson&range=any&sort=availability&page=1&per_page=10',
            },
        ];

        get getRandomImg() {
            const int = Math.floor(Math.random() * this.sliderImages.length);
            this.activeSlide = int;
            return this.sliderImages[int].src;
        }

        public goToSearch() {
            const searchModule = getModule(SearchModule, this.$store);

            searchModule.setLocation(this.location);
            searchModule.setSpecies(this.species);

            this.$router.push(searchModule.searchRoute);
        }

        private mounted() {
            const animalModule = getModule(AnimalModule, this.$store);

            animalModule
                .computeSpeciesByTypeList()
                .then((speciesByType) => this.speciesByType = speciesByType)
            ;
        }
    }
